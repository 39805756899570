import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import CustomHead from '@/pages/layout';
import Loading from '@/components/Shared/Loading';
import { ErrorScreenInformation } from '@/components/ErrorScreen';

import { useAssessmentStore, useAuthStore, useFlowStore, useTaskStore } from '@/store';

export default function AuthProvider({ children }: { children: React.ReactElement }) {
  const router = useRouter();
  const [initializing, setInitializing] = useState<boolean>(true);
  const {
    isAuthenticated,
    isAuthenticating,
    getSessionToken,
    otpToken: prevOtpToken,
    token,
    validateToken,
    error,
    authStatus,
    removeAuth
  } = useAuthStore();
  const { clear: clearFlowStore } = useFlowStore();
  const { clear: clearTaskStore } = useTaskStore();
  const { clear: clearAssessmentStore } = useAssessmentStore();

  useEffect(() => {
    removeAuth();
  }, [removeAuth]);

  useEffect(() => {
    if (!isAuthenticating && error) {
      localStorage.clear();
    }
  }, [isAuthenticating, error]);

  useEffect(() => {
    if (isAuthenticating) return;

    if (!isAuthenticated && !error && router.isReady) {
      const otpToken = router.query.token_otp as string;
      if (otpToken && otpToken !== prevOtpToken) {
        console.log('getSessionToken');
        clearFlowStore();
        clearTaskStore();
        clearAssessmentStore();
        getSessionToken(router.query.token_otp as string);
        setInitializing(false);
      } else if (token) {
        console.log('validateToken');
        validateToken();
        setInitializing(false);
      } else if (router.pathname !== '/') {
        router.push('/');
      } else {
        console.error('no token found');
      }
    }
  }, [
    isAuthenticating,
    isAuthenticated,
    token,
    prevOtpToken,
    error,
    authStatus,
    getSessionToken,
    validateToken,
    clearFlowStore,
    clearTaskStore,
    router,
    clearAssessmentStore
  ]);

  if (isAuthenticating) return <Loading />;

  if (error) return <ErrorScreenInformation />;

  if (!isAuthenticating && isAuthenticated && !error && !initializing) return children;

  return (
    <CustomHead title="Auth" description="Authenticating...">
      <Loading isFading={false} />
    </CustomHead>
  );
}
