import { FlowStepComponent, EntityId, EntityType, ProcessingStep } from '@/@types';
import { titleIcons } from '../../iconshelper';
import { getUrlLogo } from '@/utils';

const config = {
  requiredActions: ['advanced-report', 'consolidate', 'tax-folder-clave-unica', 'debt'],
  allowedEntityTypes: [EntityType.SERVICE, EntityType.GOVERMENT],
  defaultEntity: EntityId.EXPERIAN,
  enabledEntities: [EntityId.EXPERIAN, EntityId.AFC, EntityId.SII, EntityId.CMF],
  messages: [
    {
      title: 'availableInformation.advancedReportPersonalInfoTitle',
      text: 'availableInformation.advancedReportPersonalInfoText',
      icon: titleIcons.personalInfo
    },
    {
      title: 'availableInformation.advancedReportFinancialInfoTitle',
      text: 'availableInformation.advancedReportFinancialInfoText',
      icon: titleIcons.debt
    },
    {
      title: 'availableInformation.personalInfoTributaryTitle',
      text: 'availableInformation.personalInfoTributaryText',
      icon: titleIcons.tributary
    },
    {
      title: 'availableInformation.personalInfoDebtTitle',
      text: 'availableInformation.personalInfoDebtText',
      icon: titleIcons.debt
    },
    {
      title: 'availableInformation.personalWorkingInfoTitle',
      text: 'availableInformation.personalWorkingInfoText',
      icon: titleIcons.consolidate
    }
  ],
  steps: [
    {
      page: FlowStepComponent.HOME
    },
    {
      page: FlowStepComponent.EXPERIAN_FORM,
      actions: [
        `${EntityId.EXPERIAN}:advanced-report`,
        `${EntityId.AFC}:consolidate`,
        `${EntityId.SII}:tax-folder-clave-unica`,
        `${EntityId.CMF}:debt`
      ]
    },
    {
      page: FlowStepComponent.PROCESSING,
      processingSteps: [
        {
          step: ProcessingStep.Connecting,
          nextStepPct: 5
        },
        {
          step: ProcessingStep.Finding,
          nextStepPct: 10,
          image: getUrlLogo('logos', 'clave-unica-enabled.svg'),
          messageKey: 'findingMessage1'
        }
      ]
    },
    {
      page: FlowStepComponent.SUMMARY
    }
  ]
};

export default config;
