import { client } from '@/utils/apiClient';
import { EntityConfig, UserInfo, FlowStepComponent, FlowType } from '@/@types';
import { useAuthStore } from '@/store';
import { getFlowConfig } from '@/utils/flow';
import { getEnabledEntities } from '@/utils/entities';

export interface FlowTypeState {
  entities?: EntityConfig[];
  defaultEntity?: EntityConfig;
}

export const getFlowStepsFromConfig = (flowType: FlowType) => {
  const config = getFlowConfig(flowType);
  const flowSteps = config.steps.map((s) => s.page);
  return flowSteps;
};

export const getFlowStepsConfig = (flowType: FlowType) => {
  const config = getFlowConfig(flowType);
  return config.steps;
};

export const getAllowedEntities = async (flowType: FlowType): Promise<FlowTypeState> => {
  try {
    const entities = (await getEnabledEntities(flowType)) as EntityConfig[];
    const config = getFlowConfig(flowType);
    let allowedEntities: EntityConfig[];
    const hasEntitiesStep = config.steps.some((s) => s.page === FlowStepComponent.ENTITIES);
    if (hasEntitiesStep) {
      allowedEntities = entities.filter((entity) =>
        config.allowedEntityTypes.includes(entity.type)
      );
    } else {
      allowedEntities = entities;
    }

    const result: FlowTypeState = {
      entities: allowedEntities
    };

    if (config.defaultEntity) {
      const defaultEntity = allowedEntities.find((e) => e.id === config.defaultEntity);
      if (defaultEntity) {
        result.defaultEntity = defaultEntity;
      }
    }

    return result;
  } catch {
    throw new Error('Error in reqFlowTypeData');
  }
};

export const reqCheckUserEntityStatus = async (entity: string) => {
  try {
    const response = await client.post(`widget/users/status/by-entity`, {
      entity
    });
    const entitiesStatus = response.status;
    if (entitiesStatus === 422 || entitiesStatus === 404) {
      const errorMessage = `${entity} is not available`;
      throw new Error(errorMessage);
    }
    return response.data as {
      enabled: boolean;
      retryAfter: string;
      entitiesStatus: number;
    };
  } catch (error: any) {
    throw new Error('Entity selected is not available');
  }
};
