import { EntityType, EntityConfig, EntityId } from '../../@types';

export const entities: EntityConfig[] = [
  {
    id: EntityId.SANTANDER,
    name: 'Banco Santander',
    description: 'Banco Santander',
    siteUrl: 'banco.santander.cl',
    active: false,
    type: EntityType.BANK
  },
  {
    id: EntityId.CHILE,
    name: 'Banco de Chile',
    description: 'Banco de Chile',
    siteUrl: 'portales.bancochile.cl',
    active: false,
    type: EntityType.BANK
  },
  {
    id: EntityId.ESTADO,
    name: 'Banco Estado',
    description: 'Banco Estado',
    siteUrl: 'bancoestado.cl',
    active: false,
    type: EntityType.BANK
  },
  {
    id: EntityId.SCOTIABANK,
    name: 'Banco Scotiabank',
    description: 'Banco Scotiabank',
    siteUrl: 'scotiabank.cl',
    active: false,
    type: EntityType.BANK
  },
  {
    id: EntityId.ITAU,
    name: 'Banco Itaú',
    description: 'Banco Itaú',
    siteUrl: 'itau.cl',
    active: false,
    type: EntityType.BANK
  },
  {
    id: EntityId.BCI,
    name: 'BCI',
    description: 'Banco de Créditos e Inversiones',
    siteUrl: 'bci.cl',
    active: false,
    type: EntityType.BANK
  },
  {
    id: EntityId.FALABELLA,
    name: 'Banco Falabella',
    description: 'Banco Falabella',
    siteUrl: 'bancofalabella.cl',
    active: false,
    type: EntityType.BANK
  },
  {
    id: EntityId.BICE,
    name: 'Banco Bice',
    description: 'Banco Bice',
    siteUrl: 'banco.bice.cl',
    active: false,
    type: EntityType.BANK
  },
  {
    id: EntityId.CONSORCIO,
    name: 'Banco Consorcio',
    description: 'Banco Consorcio',
    siteUrl: 'bancoconsorcio.cl',
    active: false,
    type: EntityType.BANK
  },
  {
    id: EntityId.SECURITY,
    name: 'Banco Security',
    description: 'Banco Security',
    siteUrl: 'personas.bancosecurity.cl',
    active: false,
    type: EntityType.BANK
  },
  {
    id: EntityId.RIPLEY,
    name: 'Banco Ripley',
    description: 'Banco Ripley',
    siteUrl: 'bancoripley.cl',
    active: false,
    type: EntityType.BANK
  },
  {
    id: EntityId.SERVIPAG,
    name: 'Servipag',
    description: 'Servipag',
    siteUrl: 'servipag.cl',
    active: false,
    type: EntityType.SERVICE
  },
  {
    id: EntityId.EXPERIAN,
    name: 'Experian',
    description: 'Experian',
    siteUrl: 'experian.cl',
    active: false,
    type: EntityType.SERVICE
  },
  {
    id: EntityId.SII,
    name: 'Servicio de Internos (SII)',
    description: 'Servicio de Internos',
    active: true,
    type: EntityType.GOVERMENT
  },
  {
    id: EntityId.AFC,
    name: 'Seguro de Cesantía (AFC)',
    description: 'Seguro de Cesantía',
    active: true,
    type: EntityType.GOVERMENT
  },
  {
    id: EntityId.CMF,
    name: 'Comision para el Mercado de Fondos (CMF)',
    description: 'Comision para el Mercado de Fondos',
    active: true,
    type: EntityType.GOVERMENT
  },
  {
    id: EntityId.SUNAT,
    name: 'SUNAT',
    description: 'Superintendencia Nacional de Aduanas y de Administración Tributaria (SUNAT)',
    active: true,
    type: EntityType.GOVERMENT
  }
];

export const getEntitiesDict = () => {
  const entitiesDict: { [key: string]: EntityConfig } = {};
  entities.forEach((e) => {
    entitiesDict[e.id] = e;
  });
  return entitiesDict;
};
