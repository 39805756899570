import BriefcaseSvg from '@images/fluentui-emoji/briefcase_color.svg';
import LightBulbSvg from '@images/fluentui-emoji/light_bulb_color.svg';
import ShowerSvg from '@images/fluentui-emoji/shower_color.svg';
import ReceiptSvg from '@images/fluentui-emoji/receipt_color.svg';
import EMailSvg from '@images/fluentui-emoji/e-mail_color.svg';
import MoneyWithWingsSvg from '@images/fluentui-emoji/money_with_wings_color.svg';
import SeedlingSvg from '@images/fluentui-emoji/seedling_color.svg';
import TearOffCalendarSvg from '@images/fluentui-emoji/tear-off_calendar_color.svg';
import WomanRaisingHandSvg from '@images/fluentui-emoji/woman_raising_hand_color_medium.svg';
import BankSvg from '@images/fluentui-emoji/bank_color.svg';
import CreditCardSvg from '@images/fluentui-emoji/credit_card_color.svg';
import HouseSvg from '@images/fluentui-emoji/house_color.svg';
import DollarSvg from '@images/fluentui-emoji/dollar_banknote_color.svg';
import ScrollSvg from '@images/fluentui-emoji/scroll_color.svg';
import BookmarkSvg from '@images/fluentui-emoji/bookmark_tabs_color.svg';

export {
  BriefcaseSvg,
  LightBulbSvg,
  ShowerSvg,
  ReceiptSvg,
  EMailSvg,
  MoneyWithWingsSvg,
  SeedlingSvg,
  TearOffCalendarSvg,
  WomanRaisingHandSvg,
  BankSvg,
  CreditCardSvg,
  HouseSvg,
  DollarSvg,
  ScrollSvg,
  BookmarkSvg
};
