import { FlowStepComponent, EntityId, EntityType, ProcessingStep } from '@/@types';
import { ReceiptSvg } from '../../fluentEmoji';
import { titleIcons } from '../../iconshelper';

const config = {
  requiredActions: ['consolidate'],
  allowedEntityTypes: [EntityType.GOVERMENT],
  defaultEntity: EntityId.AFC,
  messages: [
    {
      title: 'availableInformation.personalInfoTitle',
      text: 'availableInformation.personalInfoText',
      icon: titleIcons.personalInfo
    },
    {
      title: 'availableInformation.personalSalaryTitle',
      text: 'availableInformation.personalSalaryText',
      icon: titleIcons.salaryInfo
    },
    {
      title: 'availableInformation.personalInfoTributaryTitle',
      text: 'availableInformation.personalInfoTributaryText',
      icon: titleIcons.tributary
    },
    {
      title: 'availableInformation.personalInfoDebtTitle',
      text: 'availableInformation.personalInfoDebtText',
      icon: titleIcons.debt
    },
    {
      title: 'availableInformation.personalWorkingInfoTitle',
      text: 'availableInformation.personalWorkingInfoText',
      icon: titleIcons.consolidate
    }
  ],
  enabledEntities: [EntityId.AFC],
  steps: [
    {
      page: FlowStepComponent.HOME
    },
    {
      page: FlowStepComponent.CREDENTIALS,
      actionType: 'consolidate'
    },
    {
      page: FlowStepComponent.PROCESSING,
      processingSteps: [
        {
          step: ProcessingStep.Connecting,
          nextStepPct: 15
        },
        {
          step: ProcessingStep.Finding,
          nextStepPct: 25,
          image: ReceiptSvg,
          messageKey: 'findingMessage1'
        },
        {
          step: ProcessingStep.Finding,
          nextStepPct: 35,
          image: ReceiptSvg,
          messageKey: 'findingMessage2'
        },
        {
          step: ProcessingStep.Finding,
          image: ReceiptSvg,
          messageKey: 'findingMessage3'
        }
      ]
    },
    {
      page: FlowStepComponent.SUMMARY
    }
  ]
};

export default config;
