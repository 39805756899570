import { FlowStepComponent, EntityId, EntityType, ProcessingStep } from '@/@types';
import { BookmarkSvg, ScrollSvg, DollarSvg } from '../../fluentEmoji';

const config = {
  requiredActions: ['tax-folder'],
  allowedEntityTypes: [EntityType.GOVERMENT],
  defaultEntity: EntityId.SII,
  enabledEntities: [EntityId.SII],
  steps: [
    {
      page: FlowStepComponent.COMPANY_INFO
    },
    {
      page: FlowStepComponent.LEGAL_INFO
    },
    {
      page: FlowStepComponent.COMPANY_CONTACT_INFO
    },
    {
      page: FlowStepComponent.CREDENTIALS,
      actionType: 'tax-folder'
    },
    {
      page: FlowStepComponent.PROCESSING,
      processingSteps: [
        {
          step: ProcessingStep.Connecting,
          nextStepPct: 15
        },
        {
          step: ProcessingStep.Finding,
          nextStepPct: 25,
          image: ScrollSvg,
          messageKey: 'findingMessage1'
        },
        {
          step: ProcessingStep.Finding,
          image: DollarSvg,
          messageKey: 'findingMessage2'
        }
      ]
    },
    {
      page: FlowStepComponent.ASSESSING,
      assessmentArgs: [
        'companyName',
        'companyId',
        'legalRepresentative',
        'legalRepresentativeId',
        'companyEmail',
        'companyPhone'
      ],
      messageKey: 'assessingMessage',
      image: BookmarkSvg
    },
    {
      page: FlowStepComponent.SUMMARY
    }
  ]
};

export default config;
