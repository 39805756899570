import { FlowStepComponent, EntityId, EntityType, ProcessingStep } from '@/@types';
import { ReceiptSvg, MoneyWithWingsSvg, BriefcaseSvg } from '../../fluentEmoji';
import { titleIcons } from '../../iconshelper';

const config = {
  requiredActions: ['tax-report'],
  allowedEntityTypes: [EntityType.GOVERMENT],
  defaultEntity: EntityId.SUNAT,
  enabledEntities: [EntityId.SUNAT],
  messages: [
    {
      title: 'availableInformation.personalInfoTaxReportTitle',
      text: 'availableInformation.personalInfoTaxReportText',
      icon: titleIcons.tributary
    }
  ],
  steps: [
    {
      page: FlowStepComponent.HOME
    },
    {
      page: FlowStepComponent.CREDENTIALS,
      actionType: 'tax-report'
    },
    {
      page: FlowStepComponent.PROCESSING,
      processingSteps: [
        {
          step: ProcessingStep.Connecting,
          nextStepPct: 15
        },
        {
          step: ProcessingStep.Finding,
          nextStepPct: 25,
          image: BriefcaseSvg,
          messageKey: 'findingMessage1'
        },
        {
          step: ProcessingStep.Finding,
          nextStepPct: 35,
          image: ReceiptSvg,
          messageKey: 'findingMessage2'
        },
        {
          step: ProcessingStep.Finding,
          image: MoneyWithWingsSvg,
          messageKey: 'findingMessage3'
        }
      ]
    },
    {
      page: FlowStepComponent.SUMMARY
    }
  ]
};

export default config;
