import { FlowStepComponent, EntityId, EntityType, ProcessingStep } from '@/@types';
import { HouseSvg, MoneyWithWingsSvg } from '../../fluentEmoji';
import { titleIcons } from '../../iconshelper';

const config = {
  requiredActions: ['property-tax'],
  allowedEntityTypes: [EntityType.GOVERMENT],
  defaultEntity: EntityId.SII,
  enabledEntities: [EntityId.SII],
  messages: [
    {
      title: 'availableInformation.personalContributionsTitle',
      text: 'availableInformation.personalContributionsText',
      icon: titleIcons.tributary
    }
  ],
  steps: [
    {
      page: FlowStepComponent.HOME
    },
    {
      page: FlowStepComponent.CREDENTIALS,
      actionType: 'property-tax'
    },
    {
      page: FlowStepComponent.PROCESSING,
      processingSteps: [
        {
          step: ProcessingStep.Connecting,
          nextStepPct: 15
        },
        {
          step: ProcessingStep.Finding,
          nextStepPct: 25,
          image: HouseSvg,
          messageKey: 'findingMessage1'
        },
        {
          step: ProcessingStep.Finding,
          nextStepPct: 35,
          image: MoneyWithWingsSvg,
          messageKey: 'findingMessage2'
        }
      ]
    },
    {
      page: FlowStepComponent.SUMMARY
    }
  ]
};

export const propertyTaxClaveUnicaConfig = {
  ...config,
  requiredActions: ['property-tax-clave-unica'],
  steps: config.steps.map((step) => {
    if (step.page === FlowStepComponent.CREDENTIALS) {
      return {
        ...step,
        actionType: 'property-tax-clave-unica'
      };
    }
    return step;
  })
};

export default config;
