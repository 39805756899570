import { FlowStepComponent, EntityId, EntityType, ProcessingStep } from '@/@types';
import { ReceiptSvg } from '../../fluentEmoji';
import { titleIcons } from '../../iconshelper';

const config = {
  requiredActions: ['income-report'],
  allowedEntityTypes: [EntityType.BANK],
  enabledEntities: [
    EntityId.ESTADO,
    EntityId.BCI,
    EntityId.CHILE,
    EntityId.SANTANDER,
    EntityId.FALABELLA,
    EntityId.SCOTIABANK,
    EntityId.ITAU,
    EntityId.RIPLEY,
    EntityId.SECURITY,
    EntityId.CONSORCIO,
    EntityId.BICE
  ],
  messages: [
    {
      title: 'availableInformation.personalSalaryTitle',
      text: 'availableInformation.personalSalaryText',
      icon: titleIcons.salaryInfo
    }
  ],
  steps: [
    {
      page: FlowStepComponent.HOME
    },
    {
      page: FlowStepComponent.ENTITIES
    },
    {
      page: FlowStepComponent.CREDENTIALS,
      actionType: 'income-report'
    },
    {
      page: FlowStepComponent.PROCESSING,
      processingSteps: [
        {
          step: ProcessingStep.Connecting,
          nextStepPct: 15
        },
        {
          step: ProcessingStep.Finding,
          nextStepPct: 25,
          image: ReceiptSvg,
          messageKey: 'findingMessage1'
        },
        {
          step: ProcessingStep.Finding,
          nextStepPct: 35,
          image: ReceiptSvg,
          messageKey: 'findingMessage2'
        },
        {
          step: ProcessingStep.Finding,
          image: ReceiptSvg,
          messageKey: 'findingMessage3'
        }
      ]
    },
    {
      page: FlowStepComponent.SUMMARY
    }
  ]
};

export default config;
