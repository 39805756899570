import { FlowStepComponent, EntityId, EntityType, ProcessingStep } from '@/@types';
import { titleIcons } from '../../iconshelper';

const config = {
  requiredActions: ['advanced-report'],
  allowedEntityTypes: [EntityType.SERVICE],
  defaultEntity: EntityId.EXPERIAN,
  messages: [
    {
      title: 'availableInformation.advancedReportPersonalInfoTitle',
      text: 'availableInformation.advancedReportPersonalInfoText',
      icon: titleIcons.personalInfo
    },
    {
      title: 'availableInformation.advancedReportFinancialInfoTitle',
      text: 'availableInformation.advancedReportFinancialInfoText',
      icon: titleIcons.debt
    }
  ],
  enabledEntities: [EntityId.EXPERIAN],
  steps: [
    {
      page: FlowStepComponent.HOME
    },
    {
      page: FlowStepComponent.EXPERIAN_FORM,
      actionType: 'advanced-report'
    },
    {
      page: FlowStepComponent.PROCESSING,
      processingSteps: [
        {
          step: ProcessingStep.Connecting,
          nextStepPct: 15
        }
      ]
    },
    {
      page: FlowStepComponent.SUMMARY
    }
  ]
};

export default config;
