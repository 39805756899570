import { FlowTypes, FlowType, FlowStep, EntityId, EntityType } from '@/@types';
import '@/utils/i18n/config';

import {
  updateSalaryConfig,
  investmentConfig,
  billConfig,
  tefConfig,
  movementConfig,
  incomeReportConfig,
  productBalanceConfig,
  financialReportConfig,
  afcConsolidateConfig,
  debtConfig,
  propertyTaxConfig,
  propertyTaxClaveUnicaConfig,
  taxFolderConfig,
  taxFolderClaveUnicaConfig,
  taxFolderPdfConfig,
  taxFolderPdfClaveUnicaConfig,
  insuranceConfig,
  consumerLoanConfig,
  newbankConfig,
  creditCardStatementConfig,
  sunatTaxReportConfig,
  experianAdvancedReportConfig,
  experianReportConfig
} from './flowsConfigs';

type FlowConfig = {
  [key: string]: {
    requiredActions?: string[];
    actionType?: string;
    allowedEntityTypes: EntityType[];
    enabledEntities: EntityId[];
    defaultEntity?: EntityId;
    requiredEntities?: EntityId[];
    messages?: {
      title: string;
      text: string;
      icon?: JSX.Element;
    }[];
    steps: FlowStep[];
  };
};

const flowConfig: FlowConfig = {
  [FlowTypes.EXTRACTION_UPDATE_SALARY]: updateSalaryConfig,
  [FlowTypes.EXTRACTION_INVESTMENT]: investmentConfig,
  [FlowTypes.EXTRACTION_BILLS]: billConfig,
  [FlowTypes.EXTRACTION_TEF]: tefConfig,
  [FlowTypes.EXTRACTION_MOVEMENT]: movementConfig,
  [FlowTypes.EXTRACTION_INCOME_REPORT]: incomeReportConfig,
  [FlowTypes.EXTRACTION_PRODUCT_BALANCE]: productBalanceConfig,
  [FlowTypes.EXTRACTION_FINANCIAL_REPORT]: financialReportConfig,
  [FlowTypes.EXTRACTION_CONSOLIDATE]: afcConsolidateConfig,
  [FlowTypes.EXTRACTION_DEBT]: debtConfig,
  [FlowTypes.EXTRACTION_PROPERTY_TAX]: propertyTaxConfig,
  [FlowTypes.EXTRACTION_PROPERTY_TAX_CLAVE_UNICA]: propertyTaxClaveUnicaConfig,
  [FlowTypes.EXTRACTION_TAX_FOLDER]: taxFolderConfig,
  [FlowTypes.EXTRACTION_TAX_FOLDER_PDF]: taxFolderPdfConfig,
  [FlowTypes.EXTRACTION_TAX_FOLDER_CLAVE_UNICA]: taxFolderClaveUnicaConfig,
  [FlowTypes.EXTRACTION_TAX_FOLDER_PDF_CLAVE_UNICA]: taxFolderPdfClaveUnicaConfig,
  [FlowTypes.EXTRACTION_INSURANCE]: insuranceConfig,
  [FlowTypes.EXTRACTION_CONSUMER_LOAN]: consumerLoanConfig,
  [FlowTypes.ASSESSMENT_NIUBANK_FLOW1]: newbankConfig,
  [FlowTypes.EXTRACTION_CREDIT_CARD_STATEMENT]: creditCardStatementConfig,
  [FlowTypes.EXTRACTION_TAX_REPORT]: sunatTaxReportConfig,
  [FlowTypes.EXTRACTION_ADVANCED_REPORT]: experianAdvancedReportConfig,
  [FlowTypes.EXTRACTION_EXPERIAN_REPORT]: experianReportConfig
};

export const getFlowConfig = (flowType: FlowType) => {
  return flowConfig[flowType];
};

export const getFlowSteps = (flowType: FlowType) => {
  return getFlowConfig(flowType).steps.map((s) => s.page);
};
