import { FlowStepComponent, EntityId, EntityType, ProcessingStep } from '@/@types';
import { BriefcaseSvg, MoneyWithWingsSvg, ReceiptSvg } from '../../fluentEmoji';
import { titleIcons } from '../../iconshelper';

const config = {
  requiredActions: ['tax-folder'],
  allowedEntityTypes: [EntityType.GOVERMENT],
  defaultEntity: EntityId.SII,
  enabledEntities: [EntityId.SII],
  messages: [
    {
      title: 'availableInformation.personalInfoTributaryTitle',
      text: 'availableInformation.personalInfoTributaryText',
      icon: titleIcons.tributary
    }
  ],
  steps: [
    {
      page: FlowStepComponent.HOME
    },
    {
      page: FlowStepComponent.CREDENTIALS,
      actionType: 'tax-folder'
    },
    {
      page: FlowStepComponent.PROCESSING,
      processingSteps: [
        {
          step: ProcessingStep.Connecting,
          nextStepPct: 15
        },
        {
          step: ProcessingStep.Finding,
          nextStepPct: 25,
          image: BriefcaseSvg,
          messageKey: 'findingMessage1'
        },
        {
          step: ProcessingStep.Finding,
          nextStepPct: 35,
          image: ReceiptSvg,
          messageKey: 'findingMessage2'
        },
        {
          step: ProcessingStep.Finding,
          image: MoneyWithWingsSvg,
          messageKey: 'findingMessage3'
        }
      ]
    },
    {
      page: FlowStepComponent.SUMMARY
    }
  ]
};

export const taxFolderClaveUnicaConfig = {
  ...config,
  requiredActions: ['tax-folder-clave-unica'],
  steps: config.steps.map((step) => {
    if (step.page === FlowStepComponent.CREDENTIALS) {
      return {
        ...step,
        actionType: 'tax-folder-clave-unica'
      };
    }
    return step;
  })
};

export const taxFolderPdfConfig = {
  ...config,
  requiredActions: ['tax-folder-pdf'],
  steps: config.steps.map((step) => {
    if (step.page === FlowStepComponent.CREDENTIALS) {
      return {
        ...step,
        actionType: 'tax-folder-pdf'
      };
    }
    return step;
  })
};

export const taxFolderPdfClaveUnicaConfig = {
  ...config,
  requiredActions: ['tax-folder-pdf-clave-unica'],
  steps: config.steps.map((step) => {
    if (step.page === FlowStepComponent.CREDENTIALS) {
      return {
        ...step,
        actionType: 'tax-folder-pdf-clave-unica'
      };
    }
    return step;
  })
};

export default config;
