import { FlowStepComponent, EntityId, EntityType, ProcessingStep } from '@/@types';
import { LightBulbSvg, ShowerSvg, ReceiptSvg } from '../../fluentEmoji';
import { titleIcons } from '../../iconshelper';

const config = {
  requiredActions: ['bill'],
  allowedEntityTypes: [EntityType.SERVICE, EntityType.BANK],
  enabledEntities: [EntityId.SERVIPAG, EntityId.ESTADO],
  messages: [
    {
      title: 'availableInformation.personalBillsTitle',
      text: 'availableInformation.personalBillsText',
      icon: titleIcons.bills
    }
  ],
  steps: [
    {
      page: FlowStepComponent.HOME
    },
    {
      page: FlowStepComponent.ENTITIES
    },
    {
      page: FlowStepComponent.CREDENTIALS,
      actionType: 'bill'
    },
    {
      page: FlowStepComponent.PROCESSING,
      processingSteps: [
        {
          step: ProcessingStep.Connecting,
          nextStepPct: 15
        },
        {
          step: ProcessingStep.Finding,
          nextStepPct: 25,
          image: LightBulbSvg,
          messageKey: 'findingMessage1'
        },
        {
          step: ProcessingStep.Finding,
          nextStepPct: 35,
          image: ShowerSvg,
          messageKey: 'findingMessage2'
        },
        {
          step: ProcessingStep.Finding,
          image: ReceiptSvg,
          messageKey: 'findingMessage3'
        }
      ]
    },
    {
      page: FlowStepComponent.SUMMARY
    }
  ]
};

export default config;
