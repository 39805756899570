export { default as updateSalaryConfig } from './updateSalary';
export { default as investmentConfig } from './investment';
export { default as billConfig } from './bill';
export { default as tefConfig } from './tef';
export { default as movementConfig } from './movement';
export { default as incomeReportConfig } from './incomeReport';
export { default as productBalanceConfig } from './productBalance';
export { default as financialReportConfig } from './financialReport';
export { default as afcConsolidateConfig } from './afcConsolidate';
export { default as debtConfig } from './debt';
export { default as propertyTaxConfig, propertyTaxClaveUnicaConfig } from './propertyTax';
export {
  default as taxFolderConfig,
  taxFolderClaveUnicaConfig,
  taxFolderPdfConfig,
  taxFolderPdfClaveUnicaConfig
} from './taxFolder';
export { default as insuranceConfig } from './insurance';
export { default as consumerLoanConfig } from './consumerLoan';
export { default as newbankConfig } from './newbank';
export { default as creditCardStatementConfig } from './creditCardStatement';
export { default as sunatTaxReportConfig } from './sunatTaxReport';
export { default as experianAdvancedReportConfig } from './experianAdvancedReport';
export { default as experianReportConfig } from './experianReport';
