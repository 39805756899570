import { FlowStepComponent, EntityId, EntityType, ProcessingStep } from '@/@types';
import { BankSvg } from '../../fluentEmoji';
import { titleIcons } from '../../iconshelper';

const config = {
  requiredActions: ['consumer-loan'],
  allowedEntityTypes: [EntityType.BANK],
  enabledEntities: [EntityId.BCI, EntityId.CHILE, EntityId.SCOTIABANK],
  messages: [
    {
      title: 'availableInformation.personalConsumerTitle',
      text: 'availableInformation.personalConsumerText',
      icon: titleIcons.consumerLoan
    }
  ],
  steps: [
    {
      page: FlowStepComponent.HOME
    },
    {
      page: FlowStepComponent.ENTITIES
    },
    {
      page: FlowStepComponent.CREDENTIALS,
      actionType: 'consumer-loan'
    },
    {
      page: FlowStepComponent.PROCESSING,
      processingSteps: [
        {
          step: ProcessingStep.Connecting,
          nextStepPct: 15
        },
        {
          step: ProcessingStep.Finding,
          nextStepPct: 25,
          image: BankSvg,
          messageKey: 'findingMessage1'
        }
      ]
    },
    {
      page: FlowStepComponent.SUMMARY
    }
  ]
};

export default config;
