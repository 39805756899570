import { create } from 'zustand';
import { EntityId } from '@/@types';
import { devtools } from 'zustand/middleware';

interface TempState {
  entityPassword?: {
    entityId: EntityId;
    password: string;
  };
}

export const useTempStore = create<TempState>()(
  devtools(() => ({}), {
    name: 'temp'
  })
);
