import { client } from '@/utils/apiClient';
import {
  WidgetServiceResponse,
  TaskResult,
  TaskStatus,
  FormData,
  TaskData,
  TaskState,
  ActionTask,
  ActionFormData
} from '@/@types';
import { AxiosError } from 'axios';

interface ResponseGetTask {
  taskId: string;
  message?: string;
}

interface ResponseGetMultiTask {
  tasks: {
    action: string;
    taskId?: string;
    error?: string;
    errorCode?: number;
  }[];
}

interface taskErrorResponse {
  status: number;
  errorMessage: string;
}

interface TaskResponse {
  taskStatus: TaskStatus;
  taskStatusCode: number;
  results: TaskResult;
}

interface MultiTaskResponse {
  action: string;
  statusResponse: number;
  data: {
    taskStatus: TaskStatus;
    taskStatusCode: number;
  };
}

export const reqTask = async (
  formData: FormData,
  action?: string
): Promise<WidgetServiceResponse> => {
  const response = await client.post('widget/tasks', { ...formData, action });

  let taskId = '';
  let message = '';
  const status = response.status;
  const data = response.data as ResponseGetTask;

  if (response.status === 200) {
    taskId = data.taskId;
  }

  if (data.message) {
    message = data.message;
  }

  return {
    taskId,
    status,
    message
  };
};

export const reqTaskResult = async (taskId: string): Promise<WidgetServiceResponse> => {
  const response = await client.get(`widget/tasks/${taskId}`);

  const taskResponse = response.data as TaskResponse;

  const data: WidgetServiceResponse = {
    taskFinished: false,
    status: response.status,
    taskStatus: taskResponse.taskStatus,
    taskStatusCode: taskResponse.taskStatusCode
  };

  if (data.taskStatus === TaskStatus.Success) {
    data.results = response.data.results as TaskResult;
  }

  if (!(data.taskStatus === TaskStatus.Pending || data.taskStatus === TaskStatus.Running)) {
    data.taskFinished = true;
  }

  return data;
};

export const reqMultiTask = async (actions: ActionFormData[]): Promise<TaskData[]> => {
  const response = await client.post('widget/multi-tasks', { actions });

  const data = response.data as ResponseGetMultiTask;

  const result: TaskData[] = [];

  for (const task of data.tasks) {
    if (task.taskId && !task.error) {
      result.push({
        taskId: task.taskId,
        action: task.action,
        state: TaskState.Started,
        wrongLoginCount: 0,
        error: false,
        errorMessage: ''
      });
    } else if (task.error && task.errorCode !== 429) {
      result.push({
        taskId: '',
        action: task.action,
        state: TaskState.Failed,
        wrongLoginCount: 0,
        error: true,
        errorMessage: task.error
      });
    }
  }
  return result;
};

export const reqMultiTasksResults = async (
  tasks: ActionTask[]
): Promise<WidgetServiceResponse[]> => {
  const response = await client.post('widget/multi-tasks/results', { tasks });

  const tasksResults = response.data as MultiTaskResponse[];

  const results: WidgetServiceResponse[] = [];

  for (const task of tasksResults) {
    const data: WidgetServiceResponse = {
      action: task.action,
      taskFinished: false,
      status: task.statusResponse,
      taskStatus: task.data.taskStatus,
      taskStatusCode: task.data.taskStatusCode
    };

    if (!(data.taskStatus === TaskStatus.Pending || data.taskStatus === TaskStatus.Running)) {
      data.taskFinished = true;
    }

    results.push(data);
  }

  return results;
};

export const getTaskErrorResponse = (error: AxiosError): taskErrorResponse => {
  const response = {
    status: error.response?.status || 500,
    errorMessage: ''
  };
  const data = error.response?.data as { message: string };
  if (data && data.message) {
    response.errorMessage = data.message;
  }
  return response;
};
