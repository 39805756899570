import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { TaskStatus, FormData, TaskState, TaskData, ActionTask, ActionFormData } from '@/@types';
import {
  reqTask,
  reqTaskResult,
  getTaskErrorResponse,
  reqMultiTask,
  reqMultiTasksResults
} from '@/api';
import { AxiosError } from 'axios';

import { useFlowStore } from './flow';

interface State {
  tasks: TaskData[];
  loading: boolean;
  fetchingResult: boolean;
  error?: boolean;
  errorMessage?: string;
  errorCode?: number;
  multitask?: boolean;
  clear: () => void;
  clearTasks: () => void;
  clearTaskResult: (taskId: string) => void;
  triggerTask: (data: FormData, action: string) => void;
  triggerMultiTask: (data: ActionFormData[]) => void;
  getTaskResult: (taskId: string) => void;
  getMultiTasksResult: () => void;
  getCurrentTask: () => TaskData | undefined;
  updateTask: (taskData: TaskData) => void;
}

const defaultTaskData = {
  taskId: '',
  action: '',
  state: TaskState.Started,
  status: TaskStatus.Pending,
  statusCode: 202,
  reqStatusCode: 200,
  wrongLoginCount: 0,
  error: false,
  errorMessage: '',
  multitask: false
};

const initialStatus = {
  tasks: [],
  loading: false,
  fetchingResult: false
};

export const useTaskStore = create<State>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialStatus,
        clear: () => set(initialStatus),
        triggerTask: async (formData, action) => {
          console.log('triggerTask');
          set(() => ({ loading: true, error: false }));
          try {
            const { taskId } = await reqTask(formData, action);

            if (!taskId) {
              throw new Error('Task ID not found');
            }

            set(() => ({
              tasks: [
                ...get().tasks.filter((t) => t.action !== action),
                { ...defaultTaskData, taskId, action }
              ],
              loading: false
            }));
          } catch (e) {
            const { status, errorMessage } = getTaskErrorResponse(e as AxiosError);
            set(() => ({
              tasks: [
                ...get().tasks.filter((t) => t.action !== action),
                {
                  ...defaultTaskData,
                  action,
                  statusCode: status,
                  error: true,
                  errorMessage
                }
              ],
              loading: false
            }));
          }
        },
        getTaskResult: async (taskId) => {
          console.log('getTaskResult');
          if (get().fetchingResult) {
            return;
          }
          set(() => ({ fetchingResult: true }));
          try {
            const { status, taskStatus, taskStatusCode, taskFinished } = await reqTaskResult(
              taskId
            );

            set(() => ({
              tasks: [
                ...get().tasks.map((t) => {
                  if (t.taskId === taskId) {
                    return {
                      ...t,
                      state: taskFinished ? TaskState.Finished : TaskState.Finding,
                      status: taskStatus,
                      statusCode: taskStatusCode,
                      reqStatusCode: status,
                      wrongLoginCount:
                        taskStatus === TaskStatus.WrongLogin
                          ? t.wrongLoginCount + 1
                          : t.wrongLoginCount
                    };
                  }
                  return t;
                })
              ],
              fetchingResult: false
            }));
          } catch (e) {
            const { status, errorMessage } = getTaskErrorResponse(e as AxiosError);
            set(() => ({
              tasks: [
                ...get().tasks.map((t) => {
                  if (t.taskId === taskId) {
                    return {
                      ...t,
                      state: TaskState.Failed,
                      error: true,
                      errorMessage,
                      reqStatus: status
                    };
                  }
                  return t;
                })
              ],
              fetchingResult: false
            }));
          }
        },
        triggerMultiTask: async (formData) => {
          console.log('triggerMultiTask');
          set(() => ({ loading: true, error: false }));
          try {
            const tasks = await reqMultiTask(formData);
            const newTasks = tasks.reduce((acc, task) => {
              const existingTask = get().tasks.find((t) => t.action === task.action);
              if (existingTask) {
                return [...acc, { ...existingTask, ...task }];
              }
              return [...acc, task];
            }, [] as TaskData[]);

            set(() => ({
              tasks: newTasks,
              loading: false
            }));
          } catch (e) {
            const { status, errorMessage } = getTaskErrorResponse(e as AxiosError);
            set(() => ({
              error: true,
              errorCode: status,
              errorMessage,
              loading: false
            }));
          }
        },
        getMultiTasksResult: async () => {
          console.log('getMultiTasksResult');
          if (get().fetchingResult) {
            return;
          }
          set(() => ({ fetchingResult: true }));
          try {
            const tasks = get().tasks.map((t) => ({ action: t.action, taskId: t.taskId }));
            const tasksResults = await reqMultiTasksResults(tasks);
            const newTasks = get().tasks.reduce((acc, task) => {
              const taskResult = tasksResults.find((t) => t.action === task.action);
              if (taskResult) {
                const { taskFinished, taskStatus, taskStatusCode, status } = taskResult;
                return [
                  ...acc,
                  {
                    ...task,
                    state: taskFinished ? TaskState.Finished : TaskState.Finding,
                    status: taskStatus,
                    statusCode: taskStatusCode,
                    reqStatusCode: status,
                    wrongLoginCount:
                      taskStatus === TaskStatus.WrongLogin
                        ? task.wrongLoginCount + 1
                        : task.wrongLoginCount
                  }
                ];
              }
              return [...acc, task];
            }, [] as TaskData[]);

            set(() => ({
              tasks: newTasks,
              fetchingResult: false
            }));
          } catch (e) {
            const { status, errorMessage } = getTaskErrorResponse(e as AxiosError);
            set(() => ({
              error: true,
              errorCode: status,
              errorMessage,
              fetchingResult: false
            }));
          }
        },
        clearTaskResult: (taskId) => {
          set(() => ({
            tasks: [...get().tasks.filter((t) => t.taskId !== taskId)]
          }));
        },
        clearTasks: () => {
          set(() => ({
            tasks: []
          }));
        },
        updateTask: (taskData) => {
          set(() => ({
            tasks: [
              ...get().tasks.map((t) => {
                if (t.taskId === taskData.taskId) {
                  return {
                    ...t,
                    ...taskData
                  };
                }
                return t;
              })
            ]
          }));
        },
        getCurrentTask: () => {
          const selectedEntity = useFlowStore.getState().selectedEntity;
          const currentAction = useFlowStore.getState().currentAction;
          if (selectedEntity && currentAction) {
            return get().tasks.find((t) => t.action === `${selectedEntity.id}:${currentAction}`);
          }
        }
      }),
      {
        name: 'task'
      }
    )
  )
);
