import {
  IconUsers,
  IconUserCircle,
  IconCashBanknote,
  IconSeeding,
  IconAbacus,
  IconWiper,
  IconBriefcase,
  IconDroplet,
  IconCreditCard,
  IconShoppingCart,
  IconHome,
  IconId,
  IconCash,
  IconFileSpreadsheet,
  IconReceipt2,
  IconReportMoney,
  IconReceiptTax,
  IconHomeDollar,
  IconFileInvoice,
  IconZoomMoney,
  IconShieldCheck,
  IconMoneybag,
  IconCurrencyBitcoin
} from '@tabler/icons-react';

export const titleIcons = {
  personalInfo: <IconUserCircle />,
  salaryInfo: <IconCashBanknote />,
  investment: <IconSeeding />,
  tefIcon: <IconUsers />,
  tributary: <IconAbacus />,
  debt: <IconWiper />,
  consolidate: <IconBriefcase />,
  bills: <IconDroplet />,
  bankingInfo: <IconCreditCard />,
  mortgage: <IconHome />,
  consumerLoan: <IconShoppingCart />,
  insurance: <IconShieldCheck />
};

const actionsIcons: { [key: string]: JSX.Element } = {
  tef: <IconUsers />,
  bill: <IconDroplet />,
  'identity-validation': <IconId />,
  'identity-validation-clave-unica': <IconId />,
  transfer: <IconCash />,
  movement: <IconFileSpreadsheet />,
  pat: <IconCreditCard />,
  pac: <IconReceipt2 />,
  'product-balance': <IconReportMoney />,
  'financial-report': <IconReportMoney />,
  'income-report': <IconReportMoney />,
  'tax-folder': <IconReceiptTax />,
  'tax-folder-clave-unica': <IconReceiptTax />,
  'tax-situation': <IconReceiptTax />,
  'property-tax': <IconHomeDollar />,
  'property-tax-clave-unica': <IconHomeDollar />,
  invoice: <IconFileInvoice />,
  'invoice-clave-unica': <IconFileInvoice />,
  debt: <IconZoomMoney />,
  insurance: <IconShieldCheck />,
  'consumer-loan': <IconMoneybag />,
  contribution: <IconHomeDollar />,
  job: <IconBriefcase />,
  consolidate: <IconBriefcase />,
  'investment-report': <IconSeeding />,
  balances: <IconCurrencyBitcoin />
};

export const getActionIcon = (action: string) => {
  return actionsIcons[action] || <IconBriefcase />;
};
